import { Link } from 'react-router-dom';
import classes from './QuoteItem.module.css';

const QuoteItem = (props) => {
  return (
    <li className={classes.item}>
      <figure>
        <blockquote>
          <p className={classes.quote}>{props.text}</p>
        </blockquote>
        <figcaption>
          {props.author}
        </figcaption>
        <figcaption>
          {props.category && (
            <span>
              Category:
              <Link className="category" to={`/categories/${props.category}`}> {props.category}</Link>
            </span>
          )}
        </figcaption>
      </figure>
      <Link className='btn' to={`/quotes/${props.id}`}>
        View
      </Link>
    </li >
  );
};

export default QuoteItem;

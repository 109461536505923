import React, { Fragment, useEffect } from 'react';
import useHttp from '../hooks/use-http';
import { useHistory } from 'react-router-dom';
import QuoteForm from '../components/quotes/QuoteForm';
import { addQuote } from '../lib/api';

function NewQuote() {
  const { sendRequest, status } = useHttp(addQuote);
  const history = useHistory();

  useEffect(() => {
    if (status === 'completed') {
      history.push('/quotes');
    }
  }, [status, history]);

  function add(quoteData) {
    sendRequest(quoteData);
  }

  return (
    <Fragment>
      <div className="quote-container">
        <h5 className="mb-2">📝 New Quote</h5>
        <QuoteForm isLoading={status === 'pending'} onAddQuote={add} />
      </div>
    </Fragment>
  );
}

export default NewQuote;
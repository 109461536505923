import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

function SearchFormMb(props) {
    const [query, setQuery] = useState('');
    const history = useHistory();

    function handleSubmit(event) {
        event.preventDefault();

        history.push(`/search/${query}`);
        props.onSubmitQuery();
        setQuery('');
    }

    return (
        <Fragment>
            <form className={`form-combo bg-light rounded px-2 py-1 ms-auto  d-sm-inline-block`} onSubmit={handleSubmit}>
                <input
                    className="search"
                    type="text"
                    placeholder="Search..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <button className="bg-trans rounded border-0 px-2"><i className="bi bi-search"></i></button>
            </form>
        </Fragment>
    );
}

export default SearchFormMb;
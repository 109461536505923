import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useHttp from '../hooks/use-http';
import { getQuotesByQuery } from '../lib/api';
import QuoteList from '../components/quotes/QuoteList';
import NoQuotesFound from '../components/quotes/NoQuotesFound';
import LoadingSpinner from '../components/UI/LoadingSpinner';

function SearchResults(props) {
  const params = useParams();
  const { query } = params;

  const { sendRequest, status, data: loadedQuotes, error } = useHttp(getQuotesByQuery, true);

  useEffect(() => {
    sendRequest(query.toLowerCase());
  }, [sendRequest, query]);

  if (status === 'pending') {
    return (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <p className="centered focused">
        {error}
      </p>
    );
  }

  if (status === 'completed' && (!loadedQuotes || loadedQuotes.length === 0)) {
    return <NoQuotesFound />;
  }

  return (
    <div>
      <h5>Search results for: {query}</h5>
      <QuoteList quotes={loadedQuotes} />
    </div>
  );
}

export default SearchResults;
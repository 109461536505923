import { Link } from 'react-router-dom';
import classes from './HighlightedQuote.module.css';

const HighlightedQuote = (props) => {
  return (
    <figure className={classes.quote}>
      <p>{props.text}</p>
      <figcaption>{props.author}</figcaption>
      <figcaption>Category: <Link className="category" to={`/categories/${props.category}`}>
        {props.category}
      </Link>
      </figcaption>
    </figure>
  );
};

export default HighlightedQuote;

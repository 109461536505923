import React, { useState, Fragment } from 'react';
import SearchForm from './SearchForm';
import SearchFormMb from './SearchFormMb';
import { NavLink } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import classes from './MainNavigation.module.css';

function MainNavigation() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Offcanvas className="navDrawerMb" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton={false} className="mt-3">
          <Offcanvas.Title>
            <SearchFormMb onSubmitQuery={handleClose} />
          </Offcanvas.Title>
          <button onClick={handleClose} className="border-0 rounded bg-trans px-2 py-1 text-light"><i className="bi bi-x-lg"></i></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NavLink to="/" className="navLinkMb py-2" onClick={(e) => setShow(false)}>
            All Quotes
          </NavLink>
          <NavLink to="/new-quote" className="navLinkMb py-2" onClick={(e) => setShow(false)}>
            Contribute
          </NavLink>
        </Offcanvas.Body>
        <p className="p-3 text-secondary">Made by <span className="fs-1">🐨</span></p>
      </Offcanvas>

      <header>
        <div className="container-xxl px-md-5">
          <div className="d-flex align-items-center">
            <nav className="d-inline-flex py-2">
              <button className="fs-3 d-sm-none me-2 text-light bg-trans border-0" onClick={handleShow}><i className="bi bi-list"></i></button>
              <NavLink to="/" className="text-light me-3 py-2"><h4>Quote For Thought</h4></NavLink>
              <span className={`${classes.nav} d-none d-sm-block`}>
                <NavLink exact to="/" className="mt-2" activeClassName={classes.active}>
                  All Quotes
                </NavLink>
                <NavLink to="/new-quote" activeClassName={classes.active}>
                  Contribute
                </NavLink>
              </span>
            </nav>
            <SearchForm />
          </div>
        </div>
      </header>
    </Fragment>
  );
}

export default MainNavigation;

import classes from './CommentItem.module.css';

const CommentItem = (props) => {
  return (
    <li className={classes.item}>
      <p className={classes['comment-author']}>{props.author}</p>
      <p className={classes['comment-date']}>Posted {props.date} at {props.time}</p>
      <p className="comment-text">{props.text}</p>
    </li>
  );
};

export default CommentItem;

import React from 'react';

function NotFound() {
  return(
    <div className="centered">
      <p className="fs-5">Page not found.</p>
    </div>
  );
}

export default NotFound;
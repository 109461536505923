import { Route, Switch, Redirect } from 'react-router-dom';
import AllQuotes from './pages/AllQuotes';
import QuoteCategory from './pages/QuoteCategory';
import SearchResults from './pages/SearchResults';
import NewQuote from './pages/NewQuote';
import QuoteDetail from './pages/QuoteDetail';
import Layout from './components/layout/Layout';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Layout>
      <Switch>
        {/* <Route exact path="/">
          <Redirect to="/quotes" />
        </Route> */}
        <Route exact path="/">
          <AllQuotes />
        </Route>
        <Route exact path="/categories/:category">
          <QuoteCategory />
        </Route>
        <Route path="/quotes/:quoteId">
          <QuoteDetail />
        </Route>
        <Route path="/search/:query">
          <SearchResults />
        </Route>
        <Route exact path="/new-quote">
          <NewQuote />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;

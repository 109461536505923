import { useState, useEffect, useCallback, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import useHttp from '../../hooks/use-http';
import { getAllComments } from '../../lib/api';
import LoadingSpinner from '../UI/LoadingSpinner';
import classes from './Comments.module.css';
import NewCommentForm from './NewCommentForm';
import CommentsList from './CommentsList';

const Comments = () => {
  const [isAddingComment, setIsAddingComment] = useState(false);
  const params = useParams();
  const { quoteId } = params;

  const { sendRequest, status, data: loadedComments } = useHttp(getAllComments);

  useEffect(() => {
    sendRequest(quoteId);
  }, [quoteId, sendRequest]);

  const startAddCommentHandler = () => {
    setIsAddingComment(true);
  };

  const handleAddedComment = useCallback(() => {
    sendRequest(quoteId);
  }, [sendRequest, quoteId]);

  let comments;

  if (status === 'pending') {
    comments = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  if (status === 'completed' && loadedComments && loadedComments.length > 0) {
    comments =
      <Fragment>
        <p className="mt-5">Comments</p>
        <CommentsList comments={loadedComments} />
      </Fragment>;
  }

  if (status === 'completed' && (!loadedComments || loadedComments.length === 0)) {
    comments =
      <Fragment>
        <p className="centered p-3">No comments posted.</p>
      </Fragment>
  }

  return (
    <section className={classes.center}>
      {!isAddingComment && (
        <div className="d-flex justify-content-center border-top-2-gy">
          <button className={`btn mt-4`} onClick={startAddCommentHandler}>
            Add comment
          </button>
        </div>
      )}
      {isAddingComment && <NewCommentForm quoteId={quoteId} onAddedComment={handleAddedComment} />}
      {comments}
    </section>
  );
};

export default Comments;

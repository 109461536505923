import { useState, useRef, useEffect, Fragment } from 'react';
import useHttp from '../../hooks/use-http';
import { addComment } from '../../lib/api';
import LoadingSpinner from '../UI/LoadingSpinner';
import { format } from 'date-fns';
import classes from './NewCommentForm.module.css';

const NewCommentForm = (props) => {
  const commentTextRef = useRef();
  const commentAuthorRef = useRef();
  const { sendRequest, status, error } = useHttp(addComment);
  const { onAddedComment } = props;
  const [comment, setComment] = useState('');
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    if (status === 'completed' && !error) {
      onAddedComment();
    }
  }, [status, error, onAddedComment]);

  useEffect(() => {
    setIsFilled(
      comment !== ''
    );
  }, [comment]);

  const submitFormHandler = (event) => {
    event.preventDefault();
    const enteredText = commentTextRef.current.value;
    const enteredAuthor = commentAuthorRef.current.value;
    const date = new Date();

    sendRequest({
      commentData: {
        text: enteredText,
        author: enteredAuthor,
        date: format(date, 'dd MMMM yyyy'),
        time: format(date, 'H:mm')
      },
      quoteId: props.quoteId
    });
    commentTextRef.current.value = '';
    commentAuthorRef.current.value = '';
    setComment('');
  };

  function handleCommentChange(e) {
    setComment(e.target.value);
  }

  return (
    <Fragment>
      <div className="border-top-2-gy mb-4"></div>
      <form className={classes.form} onSubmit={submitFormHandler}>
        {status === 'pending' && (
          <div className="centered">
            <LoadingSpinner />
          </div>
        )}
        <div className={classes.control} onSubmit={submitFormHandler}>
          <label htmlFor='comment'>Comment</label>
          <textarea id='comment' rows='3' ref={commentTextRef} value={comment} onChange={handleCommentChange}></textarea>
          <label htmlFor="username">Username</label>
          <input id="username" type="text" ref={commentAuthorRef} placeholder="Optional" />
        </div>
        <div className={classes.actions}>
          <button className='btn' disabled={!isFilled}>Post</button>
        </div>
      </form>
    </Fragment>
  );
};

export default NewCommentForm;

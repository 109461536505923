import { useState, useRef, Fragment, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import Card from '../UI/Card';
import LoadingSpinner from '../UI/LoadingSpinner';
import classes from './QuoteForm.module.css';

const QuoteForm = (props) => {
  const [isEntering, setIsEntering] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [author, setAuthor] = useState('');
  const [text, setText] = useState('');
  const [category, setCategory] = useState('');
  const authorInputRef = useRef();
  const textInputRef = useRef();
  const categoryInputRef = useRef();

  useEffect(() => {
    setIsFilled(
      author !== '' && text !== '' && category !== ''
    );
  }, [author, text, category])

  function submitFormHandler(event) {
    event.preventDefault();

    const enteredAuthor = authorInputRef.current.value;
    const enteredText = textInputRef.current.value;
    const enteredCategory = categoryInputRef.current.value;

    props.onAddQuote({ author: enteredAuthor, text: enteredText, category: enteredCategory });
    authorInputRef.current.value = '';
    textInputRef.current.value = '';
    categoryInputRef.current.value = '';
    setAuthor('');
    setText('');
    setCategory('');
  }

  function handleFormFocus() {
    setIsEntering(true);
  }

  function handleEntering() {
    setIsEntering(false);
  }

  function handleAuthorChange(e) {
    setAuthor(e.target.value);
  }

  function handleTextChange(e) {
    setText(e.target.value);
  }

  function handleCategoryChange(e) {
    setCategory(e.target.value);
  }

  return (
    <Fragment>
      <Prompt when={isEntering} message={location => 'Are you sure you want to leave the page? All your entered data will be lost.'} />
      <Card>
        <form onFocus={handleFormFocus} className={classes.form} onSubmit={submitFormHandler}>
          {props.isLoading && (
            <div className={classes.loading}>
              <LoadingSpinner />
            </div>
          )}
          <div className={classes.newquote}>
            <div className={classes.control}>
              <label htmlFor='author'>Author</label>
              <input type='text' id='author' ref={authorInputRef} value={author} onChange={handleAuthorChange} />
            </div>
            <div className={classes.control}>
              <label htmlFor='text'>Text</label>
              <textarea id='text' rows='3' ref={textInputRef} value={text} onChange={handleTextChange}></textarea>
            </div>
            <div className={classes.control}>
              <label htmlFor='category'>Category</label>
              <input type="text" id="category" ref={categoryInputRef} value={category} onChange={handleCategoryChange} />
            </div>
            <div className={classes.actions}>
              <button onClick={handleEntering} className="btn" disabled={!isFilled}>Post</button>
            </div>
          </div>
        </form>
      </Card>
    </Fragment>
  );
};

export default QuoteForm;

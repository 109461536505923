import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useHttp from '../hooks/use-http';
import { getQuotesByCategory } from '../lib/api';
import QuoteList from '../components/quotes/QuoteList';
import NoQuotesFound from '../components/quotes/NoQuotesFound';
import LoadingSpinner from '../components/UI/LoadingSpinner';

function titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}

function QuoteCategory(props) {
    const params = useParams();
    let { category } = params;

    const { sendRequest, status, data: loadedQuotes, error } = useHttp(getQuotesByCategory, true);

    useEffect(() => {
        sendRequest(category);
    }, [sendRequest, category]);

    if (status === 'pending') {
        return (
            <div className="centered">
                <LoadingSpinner />
            </div>
        );
    }

    if (error) {
        return (
            <p className="centered focused">
                {error}
            </p>
        );
    }

    if (status === 'completed' && (!loadedQuotes || loadedQuotes.length === 0)) {
        return <NoQuotesFound />;
    }

    return (
        <div>
            <h5>Category: {titleCase(category)}</h5>
            <QuoteList quotes={loadedQuotes} />
        </div>
    );
}

export default QuoteCategory;
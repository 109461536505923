import React, { Fragment } from 'react';
import MainNavigation from './MainNavigation';
import ContainerXXL from './ContainerXXL';
import classes from './Layout.module.css';

function Layout(props) {
  return (
    <Fragment>
      <MainNavigation />
      <ContainerXXL>
        <main className={classes.main}>
          {props.children}
        </main>
      </ContainerXXL>
    </Fragment>
  );
}

export default Layout;